import React, { Component, Suspense } from "react";
import { ConfigProvider } from "antd";

import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import "./assets/main.scss";
// import db from './models'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const LoginSSO = React.lazy(() => import("./views/pages/login/LoginSSO"));
const LoginNew = React.lazy(() => import("./views/pages/login/LoginNew"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  state = {
    port: 0,
  };

  // Untuk Kalo Jalanin Pake Web
  // componentDidMount() {
  //   localStorage.setItem("port", "3002");
  // }

  // Untuk electron-dev
  // componentDidMount() {
  //   const filePath = "port.txt";
  //   let pathReal = "";
  //   const publics = window.general.publicUrl().then((a) => {
  //     pathReal = `${a}/${filePath}`;

  //     console.log("public", a);
  //     console.log("sdfgj", pathReal);

  //     fetch(pathReal, {
  //       headers: {
  //         Accept: "text/plain",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           console.log(`File exists at ${filePath}`);
  //           return response.text();
  //         } else {
  //           console.error(`File does not exist at ${filePath}`);
  //         }
  //       })
  //       .then((port) => {
  //         localStorage.setItem("port", port);
  //         localStorage.setItem("public_url", a);

  //         console.log(`the port is ${port}`);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   });
  // }

  render() {
    // useEffect(() => {
    //   db.authenticate()
    //     .then(() => {
    //       console.log('Connection has been established successfully.')
    //     })
    //     .catch((err) => {
    //       console.error('Unable to connect to the database:', err)
    //     })
    // }, [])

    return (
      <ConfigProvider
        theme={{
          token: {
            zIndexPopupBase: 9999,
          },
        }}
      >
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<LoginSSO />}
              />
              <Route
                exact
                path="/activate"
                name="Login Page"
                element={<LoginNew />}
              />
              <Route
                exact
                path="/loginold"
                name="Login Page"
                element={<Login />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                element={<Register />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </ConfigProvider>
    );
  }
}

export default App;
